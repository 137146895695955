jQuery(document).ready(function( $ ) {

  $('.pc-testimonial-slick').slick({
    infinite: true,
    dots:true,
    arrows:false,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover:false,
  });

});
