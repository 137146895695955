jQuery(document).ready(function( $ ) {

  $('.pc-front-banner--slick').slick({
    infinite: true,
    dots:false,
    arrows:false,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover:false,
  });

});
